import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
// import dogIllustration from "../images/dog-illustration.svg";

function AboutPage() {
  return (
    <Layout>
      <SEO
        keywords={[`gatsby`, `tailwind`, `react`, `tailwindcss`]}
        title="About"
      />
      <section className="">
        <div className="w-full px-4 py-12 mx-auto max-w-full">
          <h1
            style={{ lineHeight: "1.5" }}
            className=" text-center font-medium bg-clip-text text-transparent bg-gradient-to-r from-blue-600 via-purple-600 to-red-600 text-4xl md:text-6xl mb-4"
          >
            About Us
          </h1>
          <h3 className="text-justify mt-3 mb-10 text-xl font-normal text-gray-900 md:leading-snug md:text-3xl">
            Hello, We are a company with focus on providing better and more
            secure solutions to protect your property. There are few essential
            systems that every building and every business should use in order
            to protect their properties.
            <span className=" text-gray-900 bg-clip-text text-transparent bg-gradient-to-r from-green-400 to-purple-500">
              {" "}
              CCTV systems, Intrusion Alarm and Fire alarm systems{" "}
            </span>
            being few of them. We are supplier and installer of these systems.
            Along with the security and survilliance system, we also provide
            networking and communication solutions like
            <span className=" text-gray-900 bg-clip-text text-transparent bg-gradient-to-r from-green-400 to-purple-500">
              {" "}
              WiFi, Point to Point, Point to multi points, Fiber Networking
              System and Intercom System (EPABX){" "}
            </span>
          </h3>
        </div>
      </section>
    </Layout>
  );
}

export default AboutPage;
